import React from 'react';
import {BrowserRouter as Router, Switch, Route} from 'react-router-dom';
import PageScrollTop from './components/pageToTop/PageScrollTop'

// Pages import Here 
import HomeDefault from "./pages/HomeDefault";

// Import Css Here 
import 'bootstrap/dist/css/bootstrap.min.css';
import './assets/scss/style.scss';
import Contact from './elements/contact/Contact';
import AboutUs from './pages/AboutUs';


const App = () => {
    return (
        <Router>
            <PageScrollTop>
                <Switch>
                    <Route path={`${process.env.PUBLIC_URL + "/"}`} exact component={HomeDefault}/>
                    <Route path={`${process.env.PUBLIC_URL + "/contact"}`} exact component={Contact}/>
                    <Route path={`${process.env.PUBLIC_URL + "/about-us"}`} exact component={AboutUs}/>
                </Switch>
            </PageScrollTop>
        </Router>
    )
}


export default App
