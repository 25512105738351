import React, {useState} from "react";
import emailjs from "emailjs-com";

const Result = () => {
  return (
    <p className="success-message">
      Your Message has been successfully sent. I will contact you soon.
    </p>
  );
};

function ContactForm({props, formStyle}) {
  const [result, showresult] = useState(false);

  const sendEmail = e => {
    e.preventDefault();
    emailjs
      .sendForm(
        "service_p4x3hv8",
        "template_jgfr42f",
        e.target,
        "user_jrfTH2e0Ely35ZCVFdT9S"
      )
      .then(
        result => {
          console.log(result.text);
        },
        error => {
          console.log(error.text);
        }
      );
    e.target.reset();
    showresult(true);
  };

  setTimeout(() => {
    showresult(false);
  }, 5000);

  return (
    <form className={`${formStyle}`} action="mailto:hello@9amstudio.in">
      <div className="form-group">
        <input type="text" name="fullname" placeholder="Your Name" required />
      </div>

      {/* <div className="form-group">
                <input 
                type="email"
                name="email"
                placeholder="Email Address"
                required
                />
            </div> */}

      {/* <div className="form-group">
                <input 
                type="text"
                name="phone"
                placeholder="Phone Number"
                required
                />
            </div> */}

      <div className="form-group">
        <input type="text" name="subject" placeholder="Subject" required />
      </div>

      <div className="form-group">
        <textarea name="body" placeholder="Your Message" required></textarea>
      <small id="passwordHelpBlock" class="form-text text-muted">
        Please Attach Product Image When Sending Email.
      </small>
      </div>

      <div className="form-group">
        <button className="btn-default btn-large" type="submit">
          Send Email
        </button>
      </div>

      <div className="form-group">{result ? <Result /> : null}</div>
    </form>
  );
}
export default ContactForm;
