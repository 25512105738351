import React from "react";
import {Link} from "react-router-dom";
import {FiArrowRight, FiMail} from "react-icons/fi";
import SEO from "../common/SEO";
import HeaderOne from "../common/header/HeaderOne";
import HeaderTopNews from "../common/header/HeaderTopNews";
import FooterOne from "../common/footer/FooterOne";
import Copyright from "../common/footer/Copyright";
import ServiceOne from "../elements/service/ServiceOne";
import SectionTitle from "../elements/sectionTitle/SectionTitle";
import PortfolioOne from "../elements/portfolio/PortfolioOne";
import Separator from "../elements/separator/Separator";
import TimelineOne from "../elements/timeline/TimelineOne";
import VideoItem from "../elements/video/VideoItem";
import TestimonialThree from "../elements/testimonial/TestimonialThree";
import CalltoActionFive from "../elements/calltoaction/CalltoActionFive";
import BlogList from "../components/blog/itemProp/BlogList";
import BlogClassicData from "../data/blog/BlogList.json";
import PricingFour from "../elements/pricing/PricingFour";
import Contact from "../elements/contact/Contact";
import ContactOne from "../elements/contact/ContactOne";
import ContactForm from "../elements/contact/ContactForm";
import VideoTwo from "../elements/video/VideoTwo";
import BrandOne from "../elements/brand/BrandOne";
var BlogListData = BlogClassicData.slice(0, 3);

const PopupData = [
  {
    id: "01",
    image: "./images/bg/bg-image-4.jpg",
    popupLink: [
      "https://www.youtube.com/watch?v=ctsT5Y-InqE&ab_channel=Rainbow-Themes",
    ],
  },
];

const HomeDefault = () => {
  return (
    <>
      <SEO title="9AM Studio" />
      <main className="page-wrapper">
        {/* <HeaderTopNews /> */}
        <HeaderOne
          btnStyle="btn-small round btn-icon"
          HeaderSTyle="header-transparent"
        />

        {/* Start Slider Area  */}
        <div
          className="slider-area slider-style-1 variation-default height-850 bg_image"
          style={{
            backgroundImage: `url(${process.env.PUBLIC_URL}/images/bg/Home-Header-Background-Curve.png)`,
          }}
        >
          <div className="container offer-home">
            <div className="row">
              <div className="col-lg-6 centerFlex">
                <div className="inner">
                  {/* <span className="subtitle">DIGITAL CONSULTING AGENCY</span> */}
                  <h1 className="title display-one">
                  We offer 3D Product <br/>
                  Animation & Image render
                  </h1>
                  <p className="description noPadding">
                  Our studio helps you to introduce your <br />product to the world through 3d animation.
                  </p>
                  <div className="button-group">
                    <a
                      className="btn-default btn-medium round btn-icon"
                      target="_blank"
                      href="/contact"
                    >
                      Contact Us
                      <i className="icon">
                        <FiArrowRight />
                      </i>
                    </a>
                    {/* <Link className="btn-default btn-medium btn-border round btn-icon" to="#">Contact Us <i className="icon"><FiArrowRight /></i></Link> */}
                  </div>
                </div>
              </div>
              <div className="col-lg-6 centerFlex">
                <img src={`images/home/Watch.png`} alt="Brand Image" />
              </div>
            </div>
          </div>
        </div>
        {/* End Slider Area  */}

        {/* <Separator /> */}
        {/* Start Portfolio Area  */}
        <div id="portfolio_template" className="rwt-portfolio-area">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <SectionTitle
                  textAlign="text-center"
                  radiusRounded=""
                  // subtitle = "Business Projects"
                  title="Portfolio"
                  description="We provide company and finance service for <br /> startups and company business."
                />
              </div>
            </div>
            <PortfolioOne Column="col-lg-4 col-md-6 col-12 mt--30 portfolio" />
          </div>
        </div>
        {/* End Portfolio Area  */}

        {/* Start Service Area  */}
        <div className="rn-service-area rn-section-gapBottom pt--100">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <SectionTitle
                  textAlign="text-center"
                  radiusRounded=""
                  title="How we works"
                  description="We appreciate the positive transparent atmosphere.<br /> We try to understand our client's perspective to communicate clearly."
                />
              </div>
            </div>
            <ServiceOne
              serviceStyle="service__style--1 icon-circle-style"
              textAlign="text-center"
            />
          </div>
        </div>
        {/* End Service Area  */}

        {/* Start Timeline Area  */}
        {/* <div className="rwt-timeline-area rn-section-gap">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                    <SectionTitle
                                        textAlign = "text-center"
                                        radiusRounded = ""
                                        subtitle = "Working Process"
                                        title = "Business Working Process."
                                        description = "We provide company and finance service for <br /> startups and company business."
                                    />
                            </div>
                        </div>
                        <div className="row mt_dec--20">
                            <div className="col-lg-12">
                                <TimelineOne />
                            </div>
                        </div>
                    </div>
                </div> */}
        {/* End Timeline Area  */}

        {/* Start Elements Area  */}
        <div id="pricing_template" className="rwt-pricing-area rn-section-gapBottom">
          <div className="wrapper plr--100 plr_md--15 plr_sm--15">
            <div className="row mb_sm--0">
              <div className="col-lg-12">
                <SectionTitle
                  textAlign="text-center"
                  radiusRounded=""
                  subtitle=""
                  title="Pricing"
                  description="Here are our best packages for startups and enterprises. <br /> Our main goal is to make a connection between your product and the audience."
                />
              </div>
            </div>
            <PricingFour />
          </div>
        </div>


         {/* Start Brand Area  */}
         <div className="rwt-brand-area rn-section-gapBottom">
              <div className="container">
                  <div className="row">
                      <div className="col-lg-12">
                          <SectionTitle
                              textAlign = "text-center"
                              radiusRounded = ""
                              subtitle = "Our Awesome Client"
                              title = "Our Clients"
                              description = "Trusted by small & large brands, around the world."
                          />
                      </div>
                  </div>
                  
                  <div className="row">
                      <div className="col-lg-12 mt--40">
                          <BrandOne brandStyle="brand-style-1" />
                      </div>
                  </div>
              </div>
          </div>
          {/* End Brand Area  */}
        <FooterOne />
        <Copyright />
      </main>
    </>
  );
};
export default HomeDefault;
