import React from "react";
import VideoTwo from "../video/VideoTwo";
import {FiCheck} from "react-icons/fi";
import ContactUs from "./../../assets/images/about_us.jpg";
import SectionTitle from "../sectionTitle/SectionTitle";

const PopupData = [
  {
    id: "01",
    image: "./images/bg/bg-image-4.jpg",
    popupLink: [
      "https://www.youtube.com/watch?v=ctsT5Y-InqE&ab_channel=Rainbow-Themes",
    ],
  },
];

const AboutFour = ({image}) => {
  return (
    <div className="about-area about-style-4 rn-section-gap">
      <div className="container">
        <div className="row">
          <div className="col-lg-12 mb--40">
            <SectionTitle
              textAlign="text-center"
              radiusRounded=""
              subtitle="About us"
              title="About us"
              description=""
            />
          </div>
        </div>
        <div className="row row--40">
         <div className="col-lg-12 mt_md--40 mt_sm--40">
            <div className="content custom-about-page">
              <div className="inner">
                <p>
                  <p>
                    Hello, Welcome to 9am Studio. One of the best 3D studio in
                    the world. We offer 3D product animation and image
                    rendering. We come from a creative and tech skillset
                    background.
                  </p>

                  <p>
                    Our specialties are 3D Product animation, Product Demo or
                    installation videos, Social media or e-commerce product
                    presentation, and image rendering. so we know the importance
                    of delivering a clear message to the right audience. 9am
                    studio can help you to introduce your product to the world.
                  </p>

                  <p>
                    We appreciate the positive transparent atmosphere. We try to
                    understand our client's perspective to communicate clearly.
                  </p>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutFour;
