import React from "react";
import {FiActivity, FiCast, FiMap} from "react-icons/fi";
import {Link} from "react-router-dom";
import ScrollAnimation from "react-animate-on-scroll";

const ServiceList = [
  {
    icon: <FiActivity />,
    title: "Product Image",
    description:
      "Take a few images of your Product and send me.",
  },
  {
    icon: <FiCast />,
    title: "Video Meeting",
    description:
      "Get on a video meeting with us to discuss your project needs in detail. ",
  },
  {
    icon: <FiMap />,
    title: "Start Project",
    description:
      "Make script and goals for the video. Also, fix the timeline & cost for the video",
  },
  {
    icon: <FiCast />,
    title: "Sign NDA",
    description:
      "Because we understand, product security is important for our client",
  },
  {
    icon: <FiCast />,
    title: "Daily Updates",
    description:
      "You will get daily work process updates",
  },
  {
    icon: <FiCast />,
    title: "Get Delivery",
    description:
      "On the delivery date, you can get your Images/Video",
  },
];

const ServiceOne = ({textAlign, serviceStyle}) => {
  return (
    <div className="row row--15 service-wrapper">
      {ServiceList.map((val, i) => (
        <div className="col-lg-4 col-md-6 col-sm-6 col-12" key={i}>
          <ScrollAnimation
            animateIn="fadeInUp"
            animateOut="fadeInOut"
            animateOnce={true}
          >
            <div className={`service ${serviceStyle} ${textAlign}`}>
              <div className="icon">{val.icon}</div>
              <div className="content">
                <h4 className="title w-600">
                  {val.title}
                </h4>
                <p
                  className="description b1 color-gray mb--0"
                  dangerouslySetInnerHTML={{__html: val.description}}
                ></p>
              </div>
            </div>
          </ScrollAnimation>
        </div>
      ))}
    </div>
  );
};
export default ServiceOne;
