import React from 'react';

const BrandList = [
    {
        image: './images/brand/Airia_Living_Logo.png'
    },
    {
        image: './images/brand/Caligo.png'
    },
    {
        image: './images/brand/Colop.png'
    },
    {
        image: './images/brand/Herbque.png'
    },
    {
        image: './images/brand/logo.png'
    },
    {
        image: './images/brand/Metlam.png'
    },
    {
        image: './images/brand/Peerless-AV-Logo-Red-1795.png'
    },
    {
        image: './images/brand/Wilkor.png'
    },
]

const BrandOne = ({brandStyle}) => {
    return (
        <ul className={`brand-list ${brandStyle} grayScaleEffect`}>
            {BrandList.map((data, index) => (
                <li key={index}>
                    <img src={`${data.image}`} alt="Brand Image" />
                </li>
            ))}
        </ul>
    )
}

export default BrandOne;
