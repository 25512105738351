import React from 'react';
import { FiCheck } from "react-icons/fi";
import {AiOutlineClose} from "react-icons/ai"


const PricingFour = () => {
    return (
        <div className="row gx-5">

            {/* Start PRicing Table Area  */}
            <div className="col-lg-4 col-md-6 col-12">
                <div className="rn-pricing style-4">
                    <div className="pricing-table-inner">
                        <div className="pricing-header">
                            <h4 className="title">Image Render</h4>
                            <div className="pricing">
                                <div className="price-wrapper">
                                    <span className="currency">$</span>
                                    <span className="price">199</span>
                                    <span className="pricing-subtitle">/ 3 Images</span>
                                </div>
                                <span className="subtitle">3 days delivery</span>
                            </div>
                        </div>
                        <div className="pricing-body">
                            <ul className="list-style--1">
                                <li><FiCheck /> 3 Image render</li>
                                <li><FiCheck /> 3D modelling</li>
                                <li><FiCheck /> X-ray view</li>
                                <li><FiCheck /> Minimal BG</li>
                                <li><FiCheck /> HD Render</li>
                                <li><FiCheck /> 2 Revisions</li>
                            </ul>
                        </div>
                        {/* <div className="pricing-footer">
                            <a className="btn-default btn-border" href="#pricing">Purchase Now</a>
                        </div> */}
                        
                    </div>
                </div>
            </div>
            {/* End PRicing Table Area  */}

            {/* Start PRicing Table Area  */}
            
            {/* End PRicing Table Area  */}

            {/* Start PRicing Table Area  */}
            <div className="col-lg-4 col-md-6 col-12">
                <div className="rn-pricing style-4 active">
                    <div className="pricing-table-inner">
                        <div className="pricing-header">
                            <h4 className="title">Standred</h4>
                            <div className="pricing">
                                <div className="price-wrapper">
                                    <span className="currency">$</span>
                                    <span className="price">499</span>
                                    <span className="pricing-subtitle">/ video</span>
                                </div>
                                <span className="subtitle">6 days delivery</span>
                                
                            </div>
                        </div>
                        <div className="pricing-body">
                            <ul className="list-style--1">
                                <li><FiCheck /> 15-30 Sec Video</li>
                                <li><FiCheck /> Script writing</li>
                                <li><FiCheck /> 3D modelling</li>
                                <li><FiCheck /> 360° Animation</li>
                                <li><FiCheck /> Background music</li>

                                <li><FiCheck /> HD Resolution</li>
                                <li><FiCheck /> 3 Revisions</li>
                                <li className="opacity_05"><AiOutlineClose /> Voice over recording</li>
                                {/* <li className="opacity_05"><AiOutlineClose /> Storyboard</li> */}
                                <li className="opacity_05"><AiOutlineClose /> Exploded view</li>
                                <li className="opacity_05"><AiOutlineClose /> X-ray view</li>
                            </ul>
                        </div>
                        {/* <div className="pricing-footer">
                            <a className="btn-default btn-border" href="#pricing">Purchase Now</a>
                        </div> */}
                    </div>
                </div>
            </div>
            {/* End PRicing Table Area  */}

            {/* Start PRicing Table Area  */}
            <div className="col-lg-4 col-md-6 col-12">
                <div className="rn-pricing style-4">
                    <div className="pricing-table-inner">
                        <div className="pricing-header">
                            <h4 className="title">Permium</h4>
                            <div className="pricing">
                                <div className="price-wrapper">
                                    <span className="currency">$</span>
                                    <span className="price">799</span>
                                    <span className="pricing-subtitle">/ video</span>
                                </div>
                                <span className="subtitle">10 days delivery</span>
                            </div>
                        </div>
                        <div className="pricing-body">
                            <ul className="list-style--1">
                                <li><FiCheck /> 45-60 Sec Video</li>
                                <li><FiCheck /> Script writing</li>
                                {/* <li><FiCheck /> Storyboard</li> */}
                                <li><FiCheck /> 360° Animation</li>
                                <li><FiCheck /> 3D modelling</li>

                                <li><FiCheck /> Exploded view</li>
                                <li><FiCheck /> X-ray view</li>

                                <li><FiCheck /> Voice over recording</li>
                                <li><FiCheck /> Background music</li>
                                <li><FiCheck /> HD Resolution</li>
                                <li><FiCheck /> 5 Revisions</li>
                            </ul>
                        </div>
                        {/* <div className="pricing-footer">
                            <a className="btn-default btn-border" href="#pricing">Purchase Now</a>
                        </div> */}
                    </div>
                </div>
            </div>
            {/* End PRicing Table Area  */}
        </div>
    )
}
export default PricingFour;